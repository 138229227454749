import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { APP_PATH_HEIGHT, HEADER_HEIGHT } from '../../resources/constants/app';

const BlockSectionStyled = styled.div`
  > .container {
    max-width: calc(0.87 * 1440px);
    padding: 40px 40px;
    @media screen and (max-width: 1200px) {
      padding: 40px 10px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 0px 0px;
    }
  }

  @media screen and (min-width: 769px) {
    > div {
      ${props => {
        let marginTop = 0;
        let paddingTop = 0;
        if (props.fixed) {
          marginTop -= HEADER_HEIGHT;
          paddingTop += HEADER_HEIGHT;
        }
        if (props.showPath) {
          marginTop -= APP_PATH_HEIGHT;
          paddingTop += APP_PATH_HEIGHT;
        }
        if (props.showAffiliateBanner) {
          marginTop -= 40;
          paddingTop += 40;
        }

        return `
        margin-top: ${marginTop ? marginTop + 'px' : 'unset'};
        padding-top: ${paddingTop ? paddingTop + 'px !important' : 'unset'};
      `;
      }}
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1440px) {
    > div {
      min-height: ${props => props.fullScreen ? '100vh' : 'unset'};
    }
  }
`;
const BlockSection = ({ children, useContainer = true, ...restProps }) => {
  return (
    <BlockSectionStyled {...restProps}>
      {useContainer ? (
        <Container>
          {children}
        </Container>
      ) : <>{children}</>
      }
    </BlockSectionStyled>
  );
};

const mapState = state => {
  return {

  };
};

export default connect(mapState)(BlockSection);
